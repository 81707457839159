const initialState = false;

/**
 * trainingOrRetrainingStatus: Reducer to handle actions related to calling the reports API.
 *
 * @param {any} state - The current state related to the reports API call.
 * @param {any} action - The action dispatched to the reducer.
 * @returns {any} The new state after applying the action.
 */
const trainingOrRetrainingStatus = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_TRAINING_OR_RETRAINING_STATUS":
      return action?.payload;
      break;
    default:
      return state;
  }
};

export default trainingOrRetrainingStatus;
